<template>
  <div :class="wrapperClass">
    <!-- не знаю как, но это решил моросни value в the-mask -->
    <p class="hidden">
      {{ innerValue }}
    </p>
    <the-mask
      ref="phoneMask"
      :mask="mask"
      :value="innerValue"
      :tokens="plusTokens"
      :placeholder="placeholder"
      :disabled="disabled"
      masked
      type="tel"
      :class="inputClass"
      @input="onInput"
    />
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getMaskToLibPhoneNumber, findFirstCountryByCode, isCanada } from './country_telephone_data';

export default {
  name: 'PhoneInput',
  components: { TheMask },
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    defaultCountry: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      type: String,
      default: 'phone-mask-wrapper-lib',
    },
    inputClass: {
      type: String,
      default: 'phone-input-lib',
    },
  },
  data() {
    return {
      innerValue: this.value,
      countryCode: '',
      mask: '+############',
      defaultMask: '+############',
      country: '',
      isValid: false,
      plusTokens: {
        '#': { pattern: /\d/ },
        '*': { pattern: /\d|\+/ },
      },
      textIsHighlighted: false, // выделен весь текст
    };
  },
  computed: {
    currentNumber() {
      const plus = /^\+/.test(this.innerValue) || !this.innerValue ? '' : '+';
      const filteredNumber = this.innerValue ? this.innerValue.replace(/[^\d+]/g) : '';
      return `${this.mask === this.defaultMask ? plus : ''}${filteredNumber}`;
    },
  },

  watch: {
    currentNumber(value) {
      this.$emit('input', value);
      this.$emit('onValidate', {
        number: value,
        isValidByLibPhoneNumberJs: this.isValid,
        country: this.country,
      });
    },
    country(val) {
      if (val) {
        setTimeout(this.setFocusToEnd.bind(this), 0);
      }
    },
  },
  beforeMount() {
    this.updateMaskData();
  },
  mounted() {
    this.$refs.phoneMask.$el.onkeydown = this.onKeydown;
    this.$refs.phoneMask.$el.onblur = this.onBlur;
    this.$refs.phoneMask.$el.onfocus = () => {
      this.$emit('onFocus');
    };
  },

  methods: {
    onInput(value) {
      this.innerValue = value.replace(/^\+?89(\d)/, '+79$1').replace(/[^0-9]/g, '');
      this.updateMaskData();
    },

    setFocusToEnd() {
      const { length } = this.$refs.phoneMask.$el.value;
      this.$refs.phoneMask.$el.focus();
      this.$refs.phoneMask.$el.setSelectionRange(length, length);
    },

    updateMaskData() {
      const { currentNumber, innerValue, countryCode } = this;

      let phoneInfo = parsePhoneNumberFromString(currentNumber?.[0] === '+' ? currentNumber : `+${currentNumber}`);

      if (phoneInfo && !phoneInfo.country) {
        // eslint-disable-next-line default-case
        switch (phoneInfo.countryCallingCode) {
          case '44': {
            phoneInfo.country = 'GB';
            break;
          }
          case '1': {
            if (currentNumber.length > 4) {
              phoneInfo.country = isCanada(currentNumber) ? 'CA' : 'US';
            }
            break;
          }
          case '7': {
            phoneInfo.country = 'KZ';
            break;
          }
        }
      } else if (!phoneInfo && innerValue.length > 2) {
        phoneInfo = {};
        phoneInfo.country = findFirstCountryByCode(currentNumber);
      }

      const computedMask = getMaskToLibPhoneNumber(phoneInfo);
      const computedCountry = phoneInfo?.country ? phoneInfo.country?.toLowerCase() : '';

      if (computedMask && computedMask.mask) {
        this.mask = computedMask.mask;
        this.country = computedCountry;
        this.countryCode = computedMask.countryCode;
        this.isValid = phoneInfo?.isValid?.() || false;
      } else if (countryCode.length > currentNumber.length) {
        this.mask = this.defaultMask;
        this.country = '';
        this.countryCode = '';
        this.isValid = false;
      }
    },

    onBlur() {
      this.textIsHighlighted = false;
      this.$emit('onBlur');
    },
    onKeydown(e) {
      if (e.code === 'Backspace') {
        this.onClickBackspace(e);
      }

      const selectedText = document.getSelection().toString().replace(/[^0-9]/gm, '');

      if (this.textIsHighlighted || selectedText === this.innerValue) {
        this.textIsHighlighted = false;

        if (!e.ctrlKey) {
          this.onInput('');
        }
      }

      if (e.keyCode === 65 && e.ctrlKey) {
        this.textIsHighlighted = true;
      }
    },

    /**
     * При стирании клавишой backspace передвигать курсор пропустив
     * пробел или скобку потому, что компонентом the-mask они не стираются
     * и получалось дополнительное нажатие на эту кнопку.
     * @param e - html input element
     * @param index number
     */
    onClickBackspace(e, index) {
      const prevCharIndex = index || e.target.selectionStart - 2;
      const prevChar = e.target.value[prevCharIndex] || '';

      if (prevCharIndex !== e.target.value.length) {
        setTimeout(() => {
          if (prevChar.match(/[+() ]/)) {
            this.onClickBackspace(e, prevCharIndex - 1);
          } else {
            this.$refs.phoneMask.$el.focus();
            this.$refs.phoneMask.$el.setSelectionRange(prevCharIndex + 1, prevCharIndex + 1);
          }
        }, 10);
      }
    },

    reset() {
      this.innerValue = '';
    },
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}
.phone-input-lib {
  width: 100%;
}
</style>
